#contact{
  height: 90vh;
  img{
    height: 50px;
  }
  a:hover{
    color: $green;
  }
}

// .contact-method:hover {
//   transform: scale(1.5);
// }