img{
  margin-bottom: 10px;
}

.project-card{
  background-color: $green;
  border-radius: 25px;
}

h3{
  margin-top: 10px;
}

.icon{
  height: 3rem;
}

a:hover{
  color: $black;
}