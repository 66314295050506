*{
  box-sizing: border-box;
}

body{
  text-align: center;
  padding-bottom: 120px;
  color: $white;
  background-color: $black;
  .site-wrapper{
    height: 100vh;
  }
}

nav{
  position: fixed;
  top: 0;
  width: 100%;
  background-color: $black;
  .nav-link:hover{
    color: $green;
    transform: scale(1.5);
  }
}

a {
  text-decoration: none;
  color: $white;
}

ul li{
  list-style-type: none;
  padding: 0;
  margin: 0;
}

ul{
  text-align: start;
}

span{
  color: $green;
}

#top{
  margin: 0em 2em 31em;
  h2{
    padding-top: 40vh;
  }
  span{
    font-size: 2rem;
    color: $green;
  }
}

#about, #projects, #contact{
  margin: 6em 2em;
}

